import { Configuration } from './contexts/ConfigProvider/ConfigProvider';

const config: Configuration = {
  backendUrl: 'https://be.guitartnt.vn/',
  backendWs: 'wss://be.guitartnt.vn/pgws',
  clientId: '260376387653-v5dvmmhvearvhvji0bm9u61h9s70u5ir.apps.googleusercontent.com',
  resourceUrl: 'https://rs.guitartnt.vn/',
};

export default config;

import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { PageData, ExperienceType } from './models';

/***********************************/
export const useGetExperience = () => {
  const fetch = useFetch();
  return useCallback(
    (page?: number, size?: number) => {
      return fetch({
        url: `mccore/rest-api/experience${page ? '?page=' + page : ''}${
          size ? '&size=' + size : ''
        }`,
        method: 'get',
      }) as Promise<PageData<ExperienceType>>;
    },
    [fetch],
  );
};

export const useGetExperienceById = () => {
  const fetch = useFetch();
  return useCallback(
    (experienceId: number) => {
      return fetch({
        url: `mccore/rest-api/experience/${experienceId}`,
        method: 'get',
      }) as Promise<ExperienceType>;
    },
    [fetch],
  );
};

import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { PageData, DestinationType } from './models';

/***********************************/
export const useGetDestination = () => {
  const fetch = useFetch();
  return useCallback(
    (page?: number, size?: number) => {
      return fetch({
        url: `mccore/rest-api/destination${page ? '?page=' + page : ''}${
          size ? '&size=' + size : ''
        }`,
        method: 'get',
      }) as Promise<PageData<DestinationType>>;
    },
    [fetch],
  );
};

export const useGetDestinationById = () => {
  const fetch = useFetch();
  return useCallback(
    (destinationId: number) => {
      return fetch({
        url: `mccore/rest-api/destination/${destinationId}`,
        method: 'get',
      }) as Promise<DestinationType>;
    },
    [fetch],
  );
};

import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';

i18next.use(HttpApi).init({
  backend: {
    loadPath: 'https://rs.guitartnt.vn/i18n/{{lng}}/{{ns}}',
    crossDomain: false,
    withCredentials: false,
    overrideMimeType: false,
    reloadInterval: false,
    queryStringParams: { v: new Date().getTime() },
  },
  interpolation: { escapeValue: false },
  fallbackLng: localStorage.getItem('GUITARTNT_I18N_LANGUAGE') ?? 'vi',
  lng: localStorage.getItem('GUITARTNT_I18N_LANGUAGE') ?? 'vi',
  debug: false,
});

export default i18next;

import { useCallback } from 'react';
import { useFetch } from './backend-api';
import { LibraryType, ImageType } from './models';

/***********************************/
export const useGetLibraries = () => {
  const fetch = useFetch();
  return useCallback(() => {
    return fetch({
      url: `mccore/rest-api/library`,
      method: 'get',
    }) as Promise<LibraryType[]>;
  }, [fetch]);
};

export const useGetLibraryById = () => {
  const fetch = useFetch();
  return useCallback(
    (libraryId: number) => {
      return fetch({
        url: `mccore/rest-api/library/${libraryId}`,
        method: 'get',
      }) as Promise<LibraryType>;
    },
    [fetch],
  );
};
